import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MenuComponent } from '../app/menu/menu.component';
import { SubMenuComponent } from '../app/sub-menu/sub-menu.component';
import { DishComponent } from '../app/dish/dish.component';
import { PromotionComponent } from '../app/promotion/promotion.component';

const routes: Routes = [
  { path: 'menu', component: MenuComponent },
  { path: 'sub-menu', component: SubMenuComponent },
  { path: 'dish', component: DishComponent },
  { path: 'promotion', component: PromotionComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
