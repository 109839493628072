import { Component, OnInit, Inject } from '@angular/core';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Item } from '../services/api.service';

@Component({
  selector: 'app-dish',
  templateUrl: './dish.component.html',
  styleUrls: ['./dish.component.css']
})
export class DishComponent implements OnInit {
  public imageURL: string = "https://keongkee.com.my/api/public/static";

  items: Array<Item>;

  isSetapak = false;

  constructor(public dialog: MatDialog) {

  }

  ngOnInit() {
    const myUrl = window.location.origin;
    if (myUrl.indexOf('setapak') > -1) {
      this.isSetapak = true;
    }

    this.items = JSON.parse(sessionStorage.getItem("dish"));
  }

  openDialog(object) {
    if (object.meta !== null && object.meta !== undefined) {
      if (object.meta.length > 0) {
        this.dialog.open(DishProcessingFeeDialog, {
          data: object
        });
      }
    }
  }
}

@Component({
  selector: 'dish-processingfee',
  templateUrl: 'dish-processingfee.html',
})
export class DishProcessingFeeDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Item) { }
}