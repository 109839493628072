import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Item, Promotion, ApiService } from '../services/api.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public imageURL: string = "https://keongkee.com.my/api/public/static";

  shop: any;

  items: Array<Item>;
  promotions: Array<Promotion>;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) {

  }

  ngOnInit() {
    // this.fetchData();
    this.route.queryParams
      .subscribe(params => {
        this.shop = params.shop;
        this.fetchMenu(this.shop);
        this.fetchPromotion(this.shop);
      });
  }

  fetchMenu(shop) {
    this.apiService.fetchMenu(shop).subscribe(
      (data: Array<Item>) => {
        console.log(data);
        this.items = data;
      }, (err) => {
        console.log(err);
      }
    );
  }

  fetchPromotion(shop) {
    this.apiService.fetchPromotion(shop).subscribe(
      (data: Array<Promotion>) => {
        console.log(data);
        this.promotions = data;
      }, (err) => {
        console.log(err);
      }
    );
  }

  redirectTo(object) {
    console.warn("GO: \n" + JSON.stringify(object));

    if (object.subMenuPayloadList !== null) {
      if (object.subMenuPayloadList.length > 0) {
        console.warn("Entering SubMenu: \n" + JSON.stringify(object.subMenuPayloadList));

        sessionStorage.setItem("sub-menu", JSON.stringify(object.subMenuPayloadList));

        this.router.navigate(['/sub-menu']);
      }
    }
    if (object.dishPayloadList !== null) {
      if (object.dishPayloadList.length > 0) {
        console.warn("Entering Dish: \n" + JSON.stringify(object.dishPayloadList));

        sessionStorage.setItem("dish", JSON.stringify(object.dishPayloadList));

        this.router.navigate(['/dish']);
      }
    }
  }

  redirectToPromotion(promotion) {
    console.warn("GO PROMOTON: \n" + JSON.stringify(promotion));

    
    sessionStorage.setItem("promotion", JSON.stringify(promotion));

    this.router.navigate(['/promotion']);
  }
}
