import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Item {
  name: string;
  description: string;
  url: string;
  html: string;
  markdown: string;
}

export interface Promotion {
  promotionName: string;
  images: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseURL: string = "https://www.techiediaries.com/api/data.json";

  private menuURL: string = "https://keongkee.com.my/api/public/menu/";

  private promotionURL: string = "https://keongkee.com.my/api/public/promotion/";

  constructor(private httpClient: HttpClient) { }

  fetch(): Observable<Item[]> {
    return <Observable<Item[]>>this.httpClient.get(this.baseURL);
  }

  fetchMenu(shop): Observable<Item[]> {
    return <Observable<Item[]>>this.httpClient.get(this.menuURL + shop);
  }

  fetchPromotion(shop): Observable<Promotion[]> {
    return <Observable<Promotion[]>>this.httpClient.get(this.promotionURL + shop);
  }
}
