import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Item } from '../services/api.service';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent implements OnInit {
  public imageURL: string = "https://keongkee.com.my/api/public/static";

  items: Array<Item>;

  constructor(private router: Router) {

  }

  ngOnInit() {
    this.items = JSON.parse(sessionStorage.getItem("sub-menu"));
  }

  redirectTo(object) {
    console.warn("GO: \n" + JSON.stringify(object));

    if (object.dishPayloadList !== null) {
      if (object.dishPayloadList.length > 0) {
        console.warn("Entering: \n" + JSON.stringify(object.dishPayloadList));

        sessionStorage.setItem("dish", JSON.stringify(object.dishPayloadList));

        this.router.navigate(['/dish']);
      }
    }
  }
}
