import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { MatToolbarModule, MatSidenavModule, MatListModule, MatIconModule, MatCardModule, MatButtonModule, MatTooltipModule, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { MenuComponent } from '../app/menu/menu.component';
import { SubMenuComponent } from '../app/sub-menu/sub-menu.component';
import { DishComponent, DishProcessingFeeDialog } from '../app/dish/dish.component';
import { PromotionComponent } from '../app/promotion/promotion.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    SubMenuComponent,
    DishComponent,
    DishProcessingFeeDialog,
    PromotionComponent
  ],
  entryComponents: [
    DishProcessingFeeDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MDBBootstrapModulesPro.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
