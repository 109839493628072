import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Item, ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public imageURL: string = "https://keongkee.com.my/api/public/static";

  title = '强记海鲜';
  items: Array<Item>;

  deferredPrompt: any;
  showButton = false;

  isBeihai = false;
  isSelayang = false;
  isSetapak = false;

  constructor(private router: Router, private apiService: ApiService) {
    this.goHome();
  }

  ngOnInit() {
    this.goHome();
  }

  goHome() {
    const myUrl = window.location.origin;

    console.warn("Accessing via: " + myUrl);

    if (myUrl.indexOf('beihai') > -1) {
      this.isBeihai = true;
      this.title = '北海海鲜酒家';

      this.go("beihai");
    } else if (myUrl.indexOf('selayang') > -1) {
      this.isSelayang = true;
      this.title = '强记海鲜酒家 (士拉央)';

      this.go("selayang");
    } else if (myUrl.indexOf('setapak') > -1) {
      this.isSetapak = true;
      this.title = '强记海鲜酒家 (文良港)';

      this.go("setapak");
    } else {
      // Default to selayang
      this.isSelayang = true;
      this.title = '强记海鲜酒家 (士拉央)';

      this.go("selayang");
    }
  }

  go(destination) {
    this.router.navigate(['/menu'], { queryParams: { 'shop': destination } });
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }
}