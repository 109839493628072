import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Promotion } from '../services/api.service';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css']
})
export class PromotionComponent implements OnInit {
  public imageURL: string = "https://keongkee.com.my/api/public/static";

  promotion: Promotion;

  constructor(private router: Router) {

  }

  ngOnInit() {
    this.promotion = JSON.parse(sessionStorage.getItem("promotion"));
  }
}
